import { DialogTitle } from '@headlessui/react';
import type { MetaFunction } from '@remix-run/node';

import { useEffect, useState } from 'react';
import { typedjson, useTypedLoaderData } from 'remix-typedjson';

import { Button } from '~/components/dashboard/button';
import { Modal } from '~/components/modal';
import { CTA } from '~/components/page/cta';
import { isShutdownPeriod } from '~/utils/shutdown';

import { Banner } from './components/banner';
import { Features } from './components/features';

export const meta: MetaFunction = () => [
  {
    title:
      'Shape Up Meal Prep - Fresh, full of flavour and delivered to your door in just a few clicks',
  },
  {
    name: 'description',
    content:
      'Bringing fresh and nutritious meals, snacks and juices to your door. All SA produce and Family owned.',
  },
  { name: 'robots', content: 'follow' },
];

export function loader() {
  const isChristmasShutdown = isShutdownPeriod();

  return typedjson({ isChristmasShutdown });
}

export default function Index() {
  const data = useTypedLoaderData<typeof loader>();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const hasSeenModal = sessionStorage.getItem('delivery-times-modal');

    if (hasSeenModal !== 'true') {
      setTimeout(() => setShowModal(true), 1000);
    }
  }, []);

  function closeModal() {
    sessionStorage.setItem('delivery-times-modal', 'true');
    setShowModal(false);
  }

  return (
    <>
      <Modal show={showModal} onClose={closeModal}>
        <div>
          <div>
            <DialogTitle
              as='h3'
              className='text-xl font-semibold leading-6 text-zinc-900'>
              Order Cut-off Time
            </DialogTitle>

            <div className='mt-3'>
              <p className='text-sm text-zinc-500'>
                We allow placing orders at any time but have a strict cut-off
                date.
              </p>

              <p className='text-sm mt-2 text-zinc-500'>
                If you order <b>after 10:00pm ACST/ACDT each Friday</b>, you
                will <b>NOT</b> receive your order until <b>the week after</b>.
              </p>
            </div>
          </div>
        </div>

        <div className='mt-5 sm:mt-6'>
          <Button
            mode='brand'
            size='lg'
            className='w-full'
            onClick={closeModal}>
            I understand
          </Button>
        </div>
      </Modal>

      <Banner className={data.isChristmasShutdown ? '-mt-12 sm:-mt-16' : ''} />
      <Features />
      <CTA />
    </>
  );
}
